/**
 * Internal dependencies.
 */
import { isWindowAboveWidth } from '../utils/window-matches';

/**
 * Module variables.
 */
const responsive = isWindowAboveWidth( 1024 );

/**
 * Handles toggling the nav contents items.
 *
 * @param {jQuery} $nav
 * @returns {void}
 */
const toggleItems = ( $nav ) => {
	const $trigger = $nav.find( '.js-nav-contents-trigger' );
	const $items = $nav.find( '.js-nav-contents-items' );

	$trigger.toggleClass( 'is-active' );
	$items.slideToggle();
};

/**
 * Handle resize and load.
 */
$( window ).on( 'resize load', () => {
	const $navContentsItems = $( '.js-nav-contents-items' );
	const state = responsive ? 'block' : 'none';

	$navContentsItems.css( 'display', state );
} );

/**
 * Handle trigger click.
 */
$( '.js-nav-contents-trigger' ).on( 'click', function( event ) {
	event.preventDefault();

	if ( responsive ) {
		return;
	}

	toggleItems( $( this ).closest( '.nav-contents' ) );
} );

/**
 * Handle item click.
 */
$( '.js-nav-contents-items a' ).on( 'click', function() {
	if ( responsive ) {
		return;
	}

	toggleItems( $( this ).closest( '.nav-contents' ) );
} );
